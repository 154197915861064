.customHeader {
  position: sticky !important;
  top: 118px;
  z-index: 9;
}

.customHeaderDown {
  position: sticky !important;
  top: 0px;
  z-index: 6;
}

.suggestionsContainer {
  display: flex;
}
