.offerDesktop::after {
  border-left: 16px solid transparent;
  border-radius: 5px 4px 5px 98%;
  border-top: 16px solid #f5f5f5;
  content: '';
  left: 25px;
  position: absolute;
  top: 3px;
  transform: rotate(45deg);
  z-index: -1;
}

.offerDesktop label {
  min-width: 27px;
}

.offerMobile::after {
  border-left: 16px solid transparent;
  border-radius: 5px 4px 5px 98%;
  border-top: 16px solid #f5f5f5;
  content: '';
  left: 19px;
  position: absolute;
  transform: rotate(45deg);
  z-index: -1;
  top: 2.8px;
}

.textBrand {
  text-transform: capitalize;
}
