.titleMobile {
  position: absolute;
  top: 20px;
}

.containerStickyButtonsMobile {
  position: sticky !important;
  bottom: 0 !important;
  padding: 16px 10px !important;
  z-index: 99999 !important;
}

.containerStickyButtons {
  position: sticky !important;
  bottom: 0 !important;
  padding: 16px 42px !important;
}
