.quick-access__block {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.quick-access__icon svg {
  transform: scale(2.4);
}
