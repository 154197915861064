.button__makeOrder:hover {
  background: var(--color-primary-red-dark) !important;
  color: var(--color-neutral-white);
}

.button__makeOrder {
  border: var(--border-width-xs) solid var(--color-primary-red2);
  color: var(--color-primary-red2);
}

.button__primary {
  background: var(--color-primary-red-header);

}
.button__primary:hover {
  background: var(--color-primary-red-dark)!important;
}