.openModal {
  right: 1.5% !important;
  transition: right 1s ease !important;
}

.closeModal {
  right: -100% !important; 
  transition: right 1s ease !important;
}

.modalContainer {
  align-items: flex-end;
  justify-content: center;
  padding: 0;
}

.modalContainer article {
  top: 0 !important;
  right: -100%;
  transition: right 1s ease; 
}
