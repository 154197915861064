.contentOutOfStock img,
.outOfStock {
  opacity: 40%;
}

.offerDesktop::after {
  z-index: -1;
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  left: 17px;
  border-left: 16px solid transparent;
  border-radius: 5px 4px 5px 98%;
  border-top: 16px solid #f5f5f5;
  transform: rotate(45deg);
}

.productDetailsMobile {
  border-radius: 16px 16px 0 0;
  background: #fff;
  box-shadow: 0 -4px 8px 0 rgba(27, 33, 56, 0.06);
}

.addToCartMobile {
  box-shadow: 4px -6px 18px rgba(0, 0, 0, 0.05);
}

.productName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.modalContainer {
  padding: 103px 0 0 0 !important;
}

.shelf__tooltipPositionDesktopCarousel {
  top: 47%;
  left: 18%;
  transform: translate(-50%, -35%);
}
.shelf__tooltipPositionDesktopUniquePicture {
  top: 47%;
  left: 19%;
  transform: translate(-35%, -35%);
}

.shelf__tooltipPositionMobile {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -15%);
}
