.linkContainerMobile {
  align-items: center;
  display: flex;
  height: 24px !important;
}

.BackgroundContentCarouselImage {
  position: relative;
  min-width: fit-content !important;
}

.BackgroundImageContainer {
  position: relative !important;
}

.CarouselContainer {
  min-width: 100%;
  position: relative !important;
  transform: translateX(-100%) !important;
  z-index: 2;
}
