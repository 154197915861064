.toggleSwitch div {
  height: 28px !important;
  padding: 0 2px;
}

.toggleSwitch p {
  padding: 0 12px !important;
  filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.06)) drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.15));
}

.toggleSwitchSelected div {
  background-color: black !important;
}

.tycLink {
  display: content !important;
}

.dividerDotted hr {
  border-style: dotted;
}
