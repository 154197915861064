.swiper_container {
  align-items: flex-end;
  display: flex;
  overflow: scroll;
}

.swiper_container::-webkit-scrollbar {
  display: none;
}

.swiper_container2 {
  align-items: flex-end;
  display: flex;
  overflow: scroll;
}

.swiper_container2::-webkit-scrollbar {
  display: none;
}

.swiper_wrapper {
  align-items: center;
  border-bottom: 1.5px var(--color-primary-disabled) solid;
  display: flex;
  justify-content: center;
}

.swiper_primary_selection {
  align-items: center;
  border-bottom: 1.5px var(--color-base-black) solid;
  display: flex;
}

.swiper_closeIcon {
  align-items: center;
  display: flex;
}

.swiper_selectedCategory {
  align-items: center;
  border-bottom: 1.5px var(--color-primary-light-red) solid;
  color: var(--color-primary-light-red);
  display: flex;
  justify-content: center;
}
