@media (width < 80rem) {
  .welcome__modal {
    display: flex;
    justify-content: flex-end;
  }
}

.welcome__modal {
  overflow-y: hidden;
}

.welcome__modal article {
  min-height: auto !important;
  padding-bottom: 10px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
