.customTab {
  top: -4px;
  background-color: var(--color-base-white);
  min-width: 100% !important;
  position: sticky !important;
  margin-bottom: 8px;
  z-index: var(--z-index-5);
}

.customTab ul {
  background-color: var(--color-base-white);
  min-width: 100%;
}

.customTab ul li {
  background-color: var(--color-base-white);
  min-width: 50%;
}

.customTab li div {
  align-items: center;
  justify-content: center;
  padding: 0 16px 14px !important;
}
.customTab li:first-child {
  position: relative;
}

.customTab li:first-child p:before,
.customTab li:last-child p:before {
  content: '';
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../public/images/orderByGrey.svg');
  margin-right: 8px;
  width: 12px;
  height: 12px;
}
.customTab li:last-child p:before {
  background-image: url('../../public/images/facetsFiltersGrey.svg');
  height: 11.78px;
  width: 13.09px;
}

.customTab li[class*='Tabs_tabActive']:first-child p:before {
  background-image: url('../../public/images/orderByBlack.svg');
}

.customTab li[class*='Tabs_tabActive']:last-child p:before {
  background-image: url('../../public/images/facetsFiltersBlack.svg');
  height: 11.78px;
  width: 13.09px;
}
