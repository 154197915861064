.code-input:disabled {
    background-color: var(--color-base-white) !important;
}

.input-container label {
    border-radius: var(--border-radius-2xs) !important;
}

.input-container input {
    border-color: var(--color-neutral-gray-input) !important;
}