.title__backbone--desktop {
  overflow: hidden;
  border-radius: 6px;
  height: 14px;
  width: 159px;
}

.title__backbone--desktop:nth-child(2) {
  width: 197px;
}

.shelf__image--desktop {
  overflow: hidden;
  border-radius: 6px;
  height: 130px;
  width: 130px;
}

.shelf__price--desktop {
  margin-bottom: 7px;
  overflow: hidden;
  border-radius: 6px;
  height: 10px;
  width: 64px;
}

.shelf__button--desktop {
  overflow: hidden;
  border-radius: var(--border-radius-full);
  height: 34px;
  width: 34px;
}

.shelf__content--desktop {
  overflow: hidden;
  border-radius: 6px;
  width: 149px;
  height: 12px;
}

.shelf__content--desktop:nth-child(2) {
  width: 119px;
}

.shelf__bottom--desktop {
  overflow: hidden;
  border-radius: 6px;
  width: 50px;
  height: 10px;
}

.shelf__bottom--desktop:nth-child(2) {
  margin-top: 2px;
}
