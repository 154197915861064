.modal__overlay {
  padding: 0;
}

.modal__overlay > article {
  position: absolute !important;
  right: -800px;
  transition: all 0.5s ease !important;
  overflow: hidden !important;
}

.modal__container {
  right: 0 !important;
}

.show {
  right: 8px !important;
}
