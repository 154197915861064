.shelf > div {
  box-shadow: 20px 20px 80px 0px #1b21380d;
}

.shelf_disabled {
  pointer-events: none;
}

.scrollStyle::-webkit-scrollbar {
  background-color: #fff;
  width: 12px;
  height: 16px;
}

.scrollStyle::-webkit-scrollbar-track {
  background-color: #fff;
}

.scrollStyle::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

.scrollStyle::-webkit-scrollbar-button {
  display: none;
}

.showSimilarButton {
  background-color: red;
}

.modalCloseIcon {
  position: sticky !important;
  top: 0;
  left: 100%;
  background-color: #ffffff;
  right: 0;
  cursor: pointer;
}
