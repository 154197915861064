.containerStickyButtons {
  position: sticky !important;
  bottom: 0 !important;
  padding: 16px 27px !important;
}

.containerStickyButtonsMobile {
  position: sticky !important;
  bottom: 0 !important;
  padding: 16px 10px !important;
  z-index: 99999 !important;
}

.similarsButton_initial {
  background-color: var(--color-base-white);
  border: var(--color-base-black) 1.2px solid;
  color: var(--color-base-black);
}

.similarsButton_initial:hover {
  background-color: var(--color-base-white);
  border: var(--color-neutral-gray-dark) 1.2px solid;
  color: var(--color-neutral-gray-dark);
}

.similarsButton_initial:active {
  background-color: var(--color-neutral-gray-light);
  border: var(--color-neutral-gray-dark) 1.2px solid;
  color: var(--color-neutral-gray-dark);
}

.shelf__tooltipPositionMobile {
  top: 98px;
  left: 48%;
  transform: translate(-43%, -105%);
  transition: top 150ms ease-in-out;
  position: sticky !important;
}

.shelf__tooltipPositionDesktopCarousel {
  top: 85px;
  left: 50%;
  transition: top 150ms ease-in-out;
  transform: translate(-50%, -35%);
  position: absolute !important;
}

.shelf__tooltipPositionDesktopUniquePicture {
  top: 85px;
  left: 50%;
  transition: top 150ms ease-in-out;
  transform: translate(-50%, -35%);
  position: absolute !important;
}

.parentContainer {
  overflow: auto;
}
