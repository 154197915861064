.modal__mobile {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  bottom: 0;
  position: absolute !important;
}

.modal__title hr {
  border: none !important;
  border-bottom: 0.3px solid var(--color-neutral-gray-divider) !important;
}

.modal__title svg:last-of-type {
  right: 16px;
  position: absolute;
}

.modal__title p {
  margin: 0 auto;
}

.modal__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px !important;
}

.modal__content--mobile p {
  font-size: var(--font-size-md);
}

.modal__content--mobile li {
  color: var(--color-base-black);
  font-size: var(--font-size-md);
  line-height: 15px;
}
