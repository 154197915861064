.promotions-carousel__content picture img {
  cursor: pointer !important;
  border: 0.3px solid #c4c4c4;
}

.promotions-carousel__content section {
  margin: 0 -0.5rem;
}

.promotions-carousel__content section li {
  padding: 5px 0;
}

.promotions-carousel__mobile {
  width: 100vw !important;
}

.promotions-carousel__mobile > div {
  padding-left: 16px !important;
}

.promotions-carousel__benefits picture img {
  border: 0 !important;
  border-radius: 0 !important;
}

.promotions-carousel__benefits > div > div {
  padding-right: 0 !important;
}
