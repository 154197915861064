.hide__tooltip {
  right: 0;
  top: 94px !important;
  transition: top 500ms ease-in-out;
}

.default__tooltip {
  right: 0;
  top: 20px !important;
  transition: top 500ms ease-in-out;
}

.mobile__tooltip {
  left: auto;
  right: auto;
}