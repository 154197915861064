.clubFeatureBanners__container picture {
  line-height: 0;
  padding: 0;
}

.clubFeatureBanners__container picture > img {
  max-height: 200px;
}

@media (max-width: 1024px) {
  .clubFeatureBanners__container picture > img {
    max-height: 130px;
  }

  .clubFeatureBanners__container div:has(> picture)  {
    min-width: 272px !important;
  }
}