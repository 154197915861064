@import 'liveShoping.css';

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/fonts/PlusJakartaSans-VariableFont_wght.ttf');
  font-display: block;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    'Plus Jakarta Sans',
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.no-scroll {
  overflow: hidden !important;
}
.grecaptcha-badge {
  visibility: hidden;
}

@media (width <= 39.9375em) {
  :root {
    --font-weight-semibold: 500;
    --font-weight-bold: 500;
  }
}
