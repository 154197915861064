@media (width < 80rem) {
  .sign-up-info-modal__overlay {
    display: flex;
    justify-content: flex-end;
  }
}

.sign-up-info-modal__button {
  user-select: none;
}

.sign-up-info-modal__overlay div:nth-child(2) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
