.shelf__tooltipPosition {
  top: 76px;
  left: calc(50% - 86px);
}

.shelf__tooltipPosition--mobile {
  top: 76px;
  left: calc(50% - 65px);
}

.modal__bodyContainer {
  height: calc(100vh - 100px) !important;
}

.modal__header--mobile {
  min-height: 68px !important;
  align-items: center;
  margin-top: -20px;
  border-bottom: 0.3px solid var(--color-neutral-gray-divider);
}
