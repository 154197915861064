.sign-up-label__arrow {
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent var(--color-neutral-gray-dark) transparent;
}

.sign-up-label__tooltip:hover div {
  display: inherit;
}

.sign-up-label__tooltip div {
  display: none;
}

.sign-up-label__modal {
  overflow-y: hidden;
  display: flex;
  justify-content: flex-end;
}

.sign-up-label__modal div:nth-child(3) {
  max-height: 234px;
  min-height: 234px;
  justify-content: space-between;
}

.sign-up-label__modal div:nth-child(2) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.sign-up-label__button {
  user-select: none;
}
